@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: futura-pt, sans-serif  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.colorword {
  background: linear-gradient(297deg,darkviolet,blue,#ffb100,#28ff00,#00e2ff);
  background-size: 300% 300%;
  animation: gradient-animation 35s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.bg-layers {
    -webkit-animation: filter-animation 4s infinite;
    animation: filter-animation 4s infinite;
}

.bg-scale {
    -webkit-animation: filter-animation 8s infinite;
    animation: filter-scale 8s infinite;
}
  
@-webkit-keyframes filter-animation {
    0% {
      -webkit-filter: hue-rotate(0deg);
    }
    50% {
      -webkit-filter: hue-rotate(45deg);
    }
    
    100% {
      -webkit-filter: hue-rotate(0deg);
    }
}
  
@keyframes filter-animation {
    0% {
      filter: hue-rotate(0deg);
    }
    50% {
      filter: hue-rotate(45deg);
    }
    100% {
      filter: hue-rotate(0deg);
    }
}

@keyframes filter-scale {
    0% {
      transform:scale(1);
    }
    50% {
      transform:scale(1.4);
    }
    100% {
        transform:scale(.8);
    }
}

.glass {
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(5px);
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.5s;

}

.fadeIn {
  opacity: 1;
  transition: opacity 0.5s 0.5s;

}

.ButtonWrapper-sc-__sc-1qu8p4z-0 .jYCazq .BrandButton-sc-__sc-1ckh2u2-9 .eXFJBv{
  display: none !important;
}

.industry3D {
  font-family: industry-inc-3d, sans-serif;
}

.industryBase {
  font-family: industry-inc-base, sans-serif;
}

.kinescope {
  font-family: kinescope, sans-serif;
}

.museo {
  font-family: museo-slab, serif;
}

.hwt {
  font-family: hwt-slab-columbian, serif;
}